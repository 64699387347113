import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import SecondaryWideLayout from '../../components/secondary-wide-layout';
import LandingHeader, { LandingHeaderStyle } from '../../components/landing-header';
import LandingBlock, { LandingBlockStyle } from '../../components/landing-block';
import FooterGetStarted from '../../components/footer-get-started';
import Meta from '../../components/meta';

import * as Text from '../../localisation';
import { Url } from '../../url';

import Projects from '../../images/entrepreneurs-projects.svg';
import Reports from '../../images/entrepreneurs-reports.svg';

function Entrepreneurs() {
  return (
    <>
      <Meta
        title={Text.EntrepreneursPageTitle}
        url={Url.UseCaseEntrepreneurs}
      />

      <SecondaryWideLayout>
        <LandingHeader
          style={LandingHeaderStyle.WhiteTitle}
          title={Text.EntrepreneursHeaderTitle}
          text={Text.EntrepreneursHeaderText}
        />

        <LandingBlock
          title={Text.EntrepreneursGeneralTitle}
          text={Text.EntrepreneursGeneralText}
          picture={(
            <StaticImage
              src="../../images/entrepreneurs-app-preview.png"
              alt={Text.EntrepreneursGeneralPictureAlt}
              layout="fullWidth"
            />
          )}
          pictureShadowShown
          pictureBorderRound
          style={LandingBlockStyle.Gray}
        />

        <LandingBlock
          title={Text.EntrepreneursProjectsTitle}
          text={Text.EntrepreneursProjectsText}
          picture={(
            <img
              src={Projects}
              alt={Text.EntrepreneursProjectsPictureAlt}
            />
          )}
          style={LandingBlockStyle.White}
        />

        <LandingBlock
          title={Text.EntrepreneursMeetingsTitle}
          text={Text.EntrepreneursMeetingsText}
          picture={(
            <StaticImage
              src="../../images/entrepreneurs-meeting-preview.png"
              alt={Text.EntrepreneursMeetingsPictureAlt}
              layout="fullWidth"
            />
          )}
          pictureShadowShown
          pictureBorderRound
          style={LandingBlockStyle.Black}
        />

        <LandingBlock
          title={Text.EntrepreneursReportsTitle}
          text={Text.EntrepreneursReportsText}
          picture={(
            <img
              src={Reports}
              alt={Text.EntrepreneursReportsPictureAlt}
            />
          )}
          style={LandingBlockStyle.Gray}
        />

        <FooterGetStarted />
      </SecondaryWideLayout>
    </>
  );
}

export default Entrepreneurs;
